@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900&subset=latin);

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  min-height: 100vh;
  width: 100vw;
  background: #fff;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-wrapper {
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 100vh;
}

@media screen and (max-width: 600px) {
  .bg-wrapper {
    flex-direction: column;
  }

  .bg-container {
    height: 100vh;
  }
}

.bg-container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.bg-container a {
  text-decoration: none;
  color: inherit;
}

@media screen and (min-width: 600px) {
  .bg-container {
    width: 100vw;
  }
}

.bg-container.ania {
  background-image: url('./images/pokoje-u-ani.jpg');
  background-position: bottom;
}

.bg-container.krzysiek {
  background-image: url('./images/pokoje-u-krzysk_a.jpg');
  background-position: bottom;
}

.bg-container.serwy {
  background-image: url('./images/domek-serwy.jpg');
  z-index: 2;
  margin-left: -12%;
  margin-right: -12%;
}

@media screen and (min-width: 600px) {
  .bg-container.ania {
    -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
    background-position: center;
  }

  .bg-container.ania h2 {
    transform: translateX(-30%);
  }

  .bg-container.krzysiek {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
    background-position: center;
  }

  .bg-container.krzysiek h2 {
    transform: translateX(20%);
  }

  .bg-container.serwy {
    -webkit-clip-path: polygon(30% 0, 70% 0, 100% 100%, 0% 100%);
    clip-path: polygon(30% 0, 70% 0, 100% 100%, 0% 100%);
  }
}

.layer {
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.bg-container h2 {
  display: flex;

}

.ania h2, .krzysiek h2, .serwy h2 {
  line-height: 120%;
  text-align: center;
  z-index: 10;
}

.sub-header {
  position: relative;
  background-position: center;
  background-size: cover;
  min-height: 200px;
  background-repeat: no-repeat;
}

.sub-header.ania {
  background-image: url(./images/headers/u-ani.jpg);
}

.sub-header.krzysiek {
  background-image: url(./images/headers/krzysiek.jpg);
}

.sub-header.serwy {
  background-image: url(./images/headers/serwy.jpg);
}
.sub-header.terms {
  background-image: url(./images/headers/serwy.jpg);
}
.sub-header.contact {
  background-image: url(./images/headers/contact.png);
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
